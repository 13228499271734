import { FC } from 'react'

// import { LearnerCategory } from 'types/graphql'

import { NoItemsFound } from '../helpers'
import { Membership } from '../interfaces'
import { LearnerCategoryType } from '../types'

import GridItem from './GridItem'
interface ReportsGridProps {
  learnerCategories: LearnerCategoryType[]
  memberships: Membership[]
  selectedCourses: any
}

const ReportsGrid: FC<ReportsGridProps> = ({
  learnerCategories,
  memberships,
  selectedCourses,
}) => {
  // Set filteredLearnerCategories based on the presence of selectedCourses
  const filteredLearnerCategories = selectedCourses.length
    ? learnerCategories.filter((learnerCategory) =>
        selectedCourses.some((selectedCourse) =>
          learnerCategory.learnerCourses.some(
            (learnerCourse) => learnerCourse.id === selectedCourse.id,
          ),
        ),
      )
    : learnerCategories

  return (
    <div className="px-6 pb-10">
      {!filteredLearnerCategories.length && <NoItemsFound />}
      {filteredLearnerCategories.map((learnerCategory) => (
        <GridItem
          key={learnerCategory.id}
          learnerCategory={learnerCategory}
          memberships={memberships}
        />
      ))}
    </div>
  )
}

export default ReportsGrid
