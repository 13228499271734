import { FC, useState } from 'react'

// import { LearnerCategory } from 'types/graphql'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'

import { Membership } from '../interfaces'
import { LearnerCategoryType } from '../types'

import GridItemRecord from './GridItemRecord'
import GridItemSummary from './GridItemSummary'

interface GridItemProps {
  learnerCategory: LearnerCategoryType
  memberships: Membership[]
}

const GridItem: FC<GridItemProps> = ({ learnerCategory, memberships }) => {
  const [expanded, setExpanded] = useState<number[]>([])

  return (
    <div className="mb-4">
      <div className="mb-2 flex items-center gap-2">
        <p className="py-4 uppercase text-gray-400">{learnerCategory.name}</p>
        <div className="h-1 grow border-b border-gray-300"></div>
        <div className="flex">
          <div className="my-auto ml-4 mr-1 h-2 w-2 rounded bg-green-500" />
          <span className="text-xs text-gray-500">Completed</span>
        </div>
        <div className="flex">
          <div className="my-auto ml-2 mr-1 h-2 w-2 rounded bg-blue-500" />
          <span className="text-xs text-gray-500">In Progress</span>
        </div>
        <div className="flex">
          <div className="my-auto ml-2 mr-1 h-2 w-2 rounded bg-red-500" />
          <span className="text-xs text-gray-500">Not Started</span>
        </div>
      </div>
      <div className="">
        {!learnerCategory?.learnerCourses.length && (
          <p className="py-4 text-sm text-gray-400">
            No Learner Course Records Found.
          </p>
        )}
        {learnerCategory?.learnerCourses.map((learnerCourse, index) => {
          return (
            <Accordion
              key={index}
              disableGutters
              className="mb-4 bg-white before:content-[unset]"
              elevation={0}
              expanded={expanded.includes(learnerCourse.id)}
              TransitionProps={{
                timeout: 0,
              }}
              sx={{
                '& .MuiAccordionSummary-root': {
                  cursor: 'default !important',
                },
              }}
            >
              <GridItemSummary
                expanded={expanded}
                setExpanded={setExpanded}
                learnerCourse={learnerCourse}
              />
              {expanded.includes(learnerCourse.id) && (
                <AccordionDetails className="-mt-2 rounded-b border border-t-0 border-solid border-gray-300 pb-0 pt-2">
                  {!learnerCourse.reporting.attempts.length && (
                    <p
                      className="py-4 text-sm text-gray-400"
                      data-testid="course-reporting-no-records-found"
                    >
                      No Learner Course Progress Records Found.
                    </p>
                  )}
                  {learnerCourse.reporting.attempts.map((attempt, index) => (
                    <GridItemRecord
                      key={index}
                      memberships={memberships}
                      attempt={attempt}
                    />
                  ))}
                </AccordionDetails>
              )}
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default GridItem
